<template lang="pug">
a-modal(
  title="预览" 
  ok-text="确认" 
  cancel-text="取消" 
  v-model:visible="isShow" 
  @ok="isShowPreview=false"
)
  a-form
    a-form-item(label="预览")
      img.imagePreview(:src="image")

</template>

<script setup>
import { inject, reactive, ref, onMounted, defineEmits, defineProps } from 'vue'
const props = defineProps({
  isShow: Boolean,
  image: String
})
const emit = defineEmits(['update:isShow', 'update:image'])
</script>

<style lang="less" scoped>
.imagePreview {
  max-width: 400px;
  max-height: 400px;
}
.textPreview {
  border: 1px dashed #ccc;
}
</style>
