import { ref, watch, readonly, nextTick } from 'vue'
import { app } from '@/main'
import { Scene, Sprite, Label, Group } from 'spritejs'
import { nanoid } from 'nanoid'
import { pickImage, getImageSize } from '@/provider/file'

const fetch = window.$globalUtil.axios

// 工作区配置
export let workspaceOption = ref({
  width: 500,
  height: 500
})
export let imageOption = ref({
  width: 0,
  height: 0,
  x: 0,
  y: 0
})

export let currentImageUrl = ref('')
export let isCutting = ref(false)
export let cutOption = ref({
  width: 0,
  height: 0,
  x: 0,
  y: 0
})

export const handlePickImage = async () => {
  const url = await pickImage({ type: 'retouch' })
  currentImageUrl.value = url
  const size = await getImageSize(url)
  if (size[0]) {
    workspaceOption.value.width = readonly(size[0])
    workspaceOption.value.height = readonly(size[1])
    imageOption.value.width = readonly(size[0])
    imageOption.value.height = readonly(size[1])
  }
}
export const handleClear = () => {}
export const handleExport = () => {
  fetch('/boom-px-api-service/dev/ping')
}

export const handleCut = type => {
  isCutting.value = true
  const w = type.split(':')[0]
  const h = type.split(':')[1]
  const minSize = Math.min(workspaceOption.value.width, workspaceOption.value.height)
  workspaceOption.value.width = minSize
  workspaceOption.value.height = minSize
}
