<template lang="pug">
.page.workspacePage
  .workspaceContainer
    .workspaceWrap
      #workspace(v-if="currentImageUrl" :style="workspaceStyle")
        img(:src="currentImageUrl" draggable="false" v-drag="imageOption" :style="imageStyle")
      #workspaceNull(v-else @click="handlePickImage")
        UploadOutlined(style="font-size:160px")
    .statusBar
      .btn(@click="handlePickImage") 选择图片
      .btn(@click="handleClear") 清空
      .btn(@click="handleExport") 导出图片
  .controlPanel
    template(v-if="currentImageUrl")
      a-form.section
        a-form-item(label='宽')
          a-input-number(
            size='small',
            v-model:value='imageOption.width',
            :min='0',
            :step='1',
          )
        a-form-item(label='高')
          a-input-number(
            size='small',
            v-model:value='imageOption.height',
            :min='0',
            :step='1',
          )
        a-form-item(label='X')
          a-input-number(
            size='small',
            v-model:value='imageOption.x',
            :step='1',
          )
        a-form-item(label='Y')
          a-input-number(
            size='small',
            v-model:value='imageOption.y',
            :step='1',
          )
      a-form.section
        a-form-item(label='裁切' style="display: flex;")
          a-button(size='small' style="margin-right:10px" @click="handleCut('1:1')") 1:1
          a-button(size='small' style="margin-right:10px" @click="handleCut('4:3')") 4:3
          a-button(size='small' style="margin-right:10px" @click="handleCut('16:9')") 16:9
          a-button(size='small' style="margin-right:10px" @click="handleCut('')") 自定义
      a-form.section
        a-form-item(label='服务端裁切' style="display: flex;")
          a-button(v-for="(i,n) in ['1:1','4:3','16:9']" size='small' style="margin-right:10px" @click="handleCutByServer(i)") {{i}}

comPreviewDialog(v-model:isShow="isShowPreviewDialog" v-model:image="imagePreviewUrl")

</template>

<script setup>
import { inject, computed, ref } from 'vue'
import { UploadOutlined } from '@ant-design/icons-vue'
import comPreviewDialog from './module/previewDialog.vue'

const { fetch } = inject('global')

import {
  handlePickImage,
  handleClear,
  handleExport,
  currentImageUrl,
  handleCut,
  isCutting,
  workspaceOption,
  imageOption
} from './provider/index'

const workspaceStyle = computed(() => {
  return {
    width: `${workspaceOption.value.width}px`,
    height: `${workspaceOption.value.height}px`
  }
})
const imageStyle = computed(() => {
  return {
    width: `${imageOption.value.width}px`,
    height: `${imageOption.value.height}px`,
    left: `${imageOption.value.x}px`,
    top: `${imageOption.value.y}px`
  }
})

let isShowPreviewDialog = ref(false)
let imagePreviewUrl = ref('')

const handleCutByServer = val => {
  fetch
    .post('/boom-px-api-service/image/cut', {
      image: currentImageUrl.value,
      type: val
    })
    .then(url => {
      imagePreviewUrl.value = url
      isShowPreviewDialog.value = true
    })
}
</script>

<style lang="less" scoped>
.workspacePage {
  border-radius: 20px;
  overflow: hidden;
  display: flex;
}
.section {
  padding: 10px 20px;
  .ant-form-item {
    margin-bottom: 5px;
  }
}
.workspaceContainer {
  flex-grow: 1;
  height: var(--maxPageHeight);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  position: relative;
  background: var(--color-dark);
  .workspaceWrap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    height: calc(var(--maxPageHeight) - 30px);
    width: calc(100vw - 350px);
    padding: 50px;
    #workspace {
      flex-shrink: 0;
      border: 1px solid var(--color-green);
      box-shadow: 0 0 6px var(--color-green);
      position: relative;
      overflow: hidden;
      img {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    #workspaceNull {
      flex-shrink: 0;
      border: 1px solid var(--color-green);
      box-shadow: 0 0 6px var(--color-green);
      width: 500px;
      height: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 0.2);
      color: white;
    }
  }
  .statusBar {
    background: var(--color-red);
    border-top: 1px solid var(--color-red);
    border-bottom: 1px solid var(--color-red);
    box-shadow: 0 -1px 12px var(--color-red);
    position: fixed;
    left: 0;
    right: 350px;
    bottom: 0;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    color: white;
    .btn {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 12px;
      border-right: 1px solid rgba(255, 255, 255, 0.2);
      &:hover {
        background: var(--color-dark);
      }
    }
  }
}

.controlPanel {
  border-left: 1px solid var(--color-blue);
  box-shadow: -1px 0 12px var(--color-blue);
  z-index: 2;
  width: 350px;
  background: var(--color-blue);
  flex-shrink: 0;
  height: var(--maxPageHeight);
  overflow-y: auto;
  .section {
    border-bottom: 1px solid var(--color-green);
    box-shadow: 3px 0 6px var(--color-green);
  }
  .sectionTitle {
    color: white;
    font-size: 20px;
    margin-bottom: 20px;
    padding-left: 40px;
  }
}
</style>
